@import "src/themes/daikiri/styles/index.scss";
.Billing__template {
  height: 100%;

  /* .billing__card {
    box-shadow: 2px 0px 6px 0px rgba(166, 166, 166, 0.25);
    .billing-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  } */

  &-top {
    gap: 0;
    padding: 0;
    flex-direction: column;
    margin-bottom: 0 !important;

    @include sm {
      padding: 0;
      max-width: 692px;
      margin: 0 auto;
    }
    @include lg {
      flex-direction: row;
      max-width: 100%;
      gap: 24px !important;
    }

    .subscription-module-desktop {
      display: none;
      @include lg {
        display: flex;
        width: 50%;
      }
    }
  }

  &-bottom {
    gap: 0;
    padding: 0;
    flex-direction: column;
    margin-bottom: 0 !important;

    @include sm {
      padding: 0;
      max-width: 692px;
      margin: 0 auto;
    }
    @include lg {
      flex-direction: row;
      max-width: 100%;
      gap: 24px !important;
    }

    .subscription-module-mobile {
      display: block;
      @include lg {
        display: none;
      }
    }
  }

  .my-billing-dashboard__empty-box--inner.loading {
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    width: 288px;
    height: 50%;
    padding-top: 0;
    position: absolute;

    @include sm {
      height: 40%;
    }
    @include md {
      height: 50%;
    }
    @include lg {
      height: 75%;
    }
    .loading-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        height: 8em;
        width: 3em;
        color: getvar($colors, "primary", "500");
      }
    }
  }
}
