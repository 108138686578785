@import "src/themes/daikiri/styles/index.scss";
.paginator-presenter {
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0 0 space(1);

  @include md {
    margin: space(1) 0 space(3);
  }
}

.paginator-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: space(0.25);
  border-radius: 50%;
  color: getvar($colors, "neutral", "500");
  margin: 0 5px;
  transition: all 0.3s ease-in-out;

  @include md {
    gap: space(0.5);
    margin-left: space(7.5) !important;
    width: calc(100% - space(7.5));
  }

  &__item {
    padding: 4px 8px;
    border: 1px solid transparent;
    min-width: 35px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;

    @include md {
      min-width: 40px;
    }

    &__prev,
    &__next {
      color: getvar($colors, "neutral", "500");
      display: flex;
      align-items: center;
      justify-content: center;

      &--active,
      &:hover {
        background-color: transparent !important;
        border: 1px solid transparent !important;
        color: getvar($colors, "neutral", "700");
      }
      svg {
        width: 20px;
        height: 20px;
      }

      &--disabled {
        color: getvar($colors, "neutral", "300");
        background-color: unset !important;
        border: unset !important;
        cursor: default;

        &:hover {
          color: getvar($colors, "neutral", "300");
        }
      }
    }

    &__prev {
      @include md {
        margin-right: 10px;
      }
    }

    &__next {
      @include md {
        margin-left: 10px;
      }
    }

    &__dots {
      &--active,
      &:hover {
        background-color: unset !important;
        border: unset !important;
        cursor: default;
      }
    }

    &--active,
    &:hover {
      background-color: getvar($colors, "primary", "100");
      border: 1px solid getvar($colors, "primary", "500");
      border-radius: 4px;
    }

    &--disabled {
      color: getvar($colors, "neutral", "300");
    }

    &--active {
      color: getvar($colors, "neutral", "700");
      font-weight: bold;
    }
  }
}

.paginator-item-selector {
  display: none;
  width: space(7.5);
  margin-right: space(1.5);
  box-sizing: border-box;

  @include md {
    display: flex;
    align-items: center;
  }

  p,
  div {
    margin-bottom: 0 !important;
  }

  p {
    margin-right: space(0.5);
    font-size: space(0.9);
    color: getvar($colors, "neutral", "300");
  }

  div {
    min-width: space(4.5);
  }
}
